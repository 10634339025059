<template>
  <div>
    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content content-text"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Melhor insulfilm para colocar na janela</h2>

            <p>
              Qual Insulfilm deve ser indicado para janelas? Essa é uma
              questão que temos que analisar em algum momento.
            </p>
            <p>
              Qual é o propósito de aplicar películas em janelas?
              Podemos observar algumas metas peculiares em nossa vida diária.
              Podemos instalar Insulfilm em nossas casas por segurança, proteção
              solar, privacidade, beleza e design.
            </p>
            <p>
              O Insulfilm mantém você e sua família seguros, além de muitos
              outros benefícios. Ao usar um filme escuro (fumê), além da
              proteção solar que ela proporciona, o insulfilme pode evitar o
              estilhaçamento do vidro caso haja a quebra do mesmo, mantendo o
              vidro no local até que façam a substituição. Evita que pessoas
              externas tenham total visibilidade do interior de sua casa.
            </p>
            
            <p>
              Existem vários tipos de filmes protetores. Podemos defini-los por
              tons mais claros e mais escuros. No uso de carros ou em
              residências, podemos usar películas G5, G20 ou mesmo G35 em janelas ou qualquer superfície de vidro, que são bem definidas
              em termos de segurança.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>Insulfilme profissional com bloqueio de U.V</h3>

            <p>
              Por muitos anos, as películas para janelas e automóveis foram
              vistas apenas como soluções que tinham um papel importante, como
              deixar o ambiente mais bonito e seguro. Ainda outra função deste
              acessório é regular a entrada de calor, auxiliando no controle do
              clima interno e na proteção contra a exposição aos raios UV.
            </p>
            <p>
              As consequências da exposição regular da pele aos raios UV podem
              levar a doenças que vão desde o envelhecimento prematuro ao câncer
              de pele e a degradação de objetos.
            </p>
            <p>
              Toda película Profissional com mais de 5 anos de garantia tem como
              propriedades principal à de retenção de radiações ultravioleta.
              Portanto, os benefícios da película solar também se tornam um bom
              investimento para a conservação da saúde e objetos.
            </p>

            <h3>G5</h3>
            <p>
              O filme G5 é um dos filmes mais escuros com quase 100% de fator de
              transparências. Tendo maior privacidade e sombra, lembrando que as
              películas de controle solar não são feitas para dar privacidade, o
              que vai proporcionar essa condição de privacidade é o reflexo, o
              local mais iluminado do ambiente que foi colocado o insulfime é o
              lado que mais vai refletir dando essa característica de
              privacidade.
            </p>

            <h3>G20</h3>
            <p>
              O G20 é um pouco mais leve, mas ainda cabe como uma luva nas
              janelas para quem não gostaria de escurecer tanto a casa
              como faria no caso da instalação do insulfilme G5 e mantendo a
              proteção U.V.
            </p>

            <h3>
              Insulfilme de Alta Performance de Proteção U.V e Infravermelho
            </h3>

            <p>
              Os insulfilmes com tecnologia nano-carbono incorporam a tecnologia
              de revestimento rígido mais durável do mercado para um desempenho
              duradouro.
            </p>
            <p>
              Elas são infundidas com nanopartículas de bloqueio de calor
              infravermelho (IR) de alta qualidade, e ajudam a manter o ambiente
              mais fresco em condições extremas, rejeitando em até 62%.
            </p>
            <p>
              Disponível em várias tonalidades como G5- G20 e G80, bloqueando em
              99% dos raios ultra violeta.
            </p>
            <p>
              Ideal para clientes que além de escurecer e tirar os raios uv das
              janelas, precisam também de reduzir o calor.
            </p>
            <p>
              A G80 destaca-se pela sua alta performance e transparência de 76%,
              ideais para ambientes que queiram manter a transparência das
              janelas e reduzir a transmissão de radiação infravermelha (IR) e
              U.V.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[1].thumb"
                :title="photos[1].title"
                :description="photos[1].description"
                :idLocation="photos[1].idLocation"
                :openLightBox="false"
              />
            </div>

            <Table>
              <tr>
                <td>Transmissão de Luz Visível</td>
                <td class="text-right" width="20%">76%</td>
              </tr>
              <tr>
                <td>Reflexo</td>
                <td class="text-right" width="20%">10%</td>
              </tr>
              <tr>
                <td>Rejeição de IR</td>
                <td class="text-right" width="20%">76%</td>
              </tr>
              <tr>
                <td>Rejeição de U.V</td>
                <td class="text-right" width="20%">99%</td>
              </tr>
              <tr>
                <td>Total de energia solar rejeitado</td>
                <td class="text-right" width="20%">48%</td>
              </tr>
            </Table> 
           

            <h3>Aplicação do melhor insulfilm para colocar na janela</h3>

            <p>
              É possível aplicar insulfilmes para janelas com
              materiais de qualidade e com garantia de fábrica, impedindo a
              visualização de fora para o interior do ambiente, gerando
              privacidade e reduzindo o calor e radiação UV do ambiente.
            </p>

            <p>
              A <router-link :to="{ name: 'about' }">MF Film</router-link> tem
              mais de 18 anos de experiência com profissionais especializados na
              instalação de insulfilms e garante o serviço completo.
            </p>

            <p>
              <router-link :to="{ name: 'quotation' }"
                >Faça uma cotação</router-link
              >, fale com um especialista e veja como as películas podem ajudar
              na seguranca, privacidade, decoração e economia com ar condicionado.
            </p>
           
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );
const Photo = () =>
  import(/* webpackChunkName: "Photo" */ "@/components/photo/photo.vue");

  const Table = () =>
  import(
    /* webpackChunkName: "Table" */ "@/components/shared/table/table.vue"
  );

export default {
  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,
    Table
  },

  data() {
    return {
      layoutLoad: false,
      index: null,
      photos: [
        {
          description:
            "Insulfilm G5 aplicado na região de pinheiros para dar privacidade, reduzir a luz e calor",
          idLocation: 1,
          src: "/images/fotos/condominio-arruda/insulfilm-g5-guarita-condominio-arruda-134911868",
          thumb: "/images/fotos/condominio-arruda/small/insulfilm-g5-guarita-condominio-arruda-134911868",
          title: "Película para Janela",

        },
        {
          description:
            "Insulfilm para impedir a entrada dos raios UV, conservar os móveis e deixar o ambiente agradável",
          idLocation: 1,
          src: "/images/fotos/apartamento-vila-nova-conceicao/pelicula-transparente-sacada-apartamento-694",
          thumb: "/images/fotos/apartamento-vila-nova-conceicao/small/pelicula-transparente-sacada-apartamento-694",
          title: "Película para Janela",
        },
        
      ],
    };
  },

  mounted: function () {
    //console.log(this.photos)
    // console.log('mounted')
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(
      `update:title`,
      "Qual a melhor insulfilm para colocar na janela?"
    );
    this.$emit(
      `update:introduction`,      
      "Instalamos insulfilm profissional, G5, G20 e de Alta Performance nas janelas para atender sua necessidade"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>